import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
const lange = window.localStorage.getItem('lange') ?? 'en_US';

i18n 
    // .use(LanguageDetector)  //嗅探当前浏览器语言
    .use(initReactI18next) //init i18next
    .init({
        //引入资源文件
        resources: {
            en_US: {
                translation: require('./en.json'),
            },
            zh_CN: {
                translation: require('./zh_CN.json'),
            },
            ko_KR: {
                translation: require('./ko.json'),
            },
            ja: {
                translation: require('./ja.json'),
            },
            zh_HK: {
                translation: require('./zh_TW.json'),
            },
        },

        //选择默认语言，选择内容为上述配置中的key，即en/zh
        fallbackLng: lange,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
