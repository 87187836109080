import Amax from '@amax/amaxjs';

const { REACT_APP_NET, REACT_APP_NETWORK_chainId, REACT_APP_URL, REACT_APP_NETWORK_host, REACT_APP_NETWORK_port, REACT_APP_NETWORK_protocol } = process.env;

let _getClient = null;

console.log('chainId---', REACT_APP_NETWORK_chainId)

export const network = {
    blockchain: 'amax',
    expireInSeconds: 600,
    // host: '913.224.250.244', // ( or null if endorsed chainId )
    host: REACT_APP_NETWORK_host, // ( or null if endorsed chainId )
    port: REACT_APP_NETWORK_port, // ( or null if defaulting to 80 )
    chainId: REACT_APP_NETWORK_chainId, // Or null to fetch automatically ( takes longer )
    protocol: REACT_APP_NETWORK_protocol,
};

const options = {
    broadcast: true,
    sign: true,
    expireInSeconds: 200,
    chainId: network.chainId,
};

export async function getClient() {
    if (_getClient) return _getClient;

    const scatter = getScatter();
    if (scatter) {
        if (!(window as any).scatterAMAX) {
            const identity = await scatter.getIdentity({
                accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
            });

            const account = identity?.accounts[0];
            (window as any).scatterAMAX = scatter.amax(
                network,
                Amax,
                {
                    ...options,
                    authorization: [`${account.name}@${account.authority}`],
                },
                network.protocol
            );
        }
        _getClient = (window as any).scatterAMAX;
        return _getClient;
    }

    /**
     * 有些数据不需要连接钱包，也可以展示
     */
    if (!(window as any).AMAX) {
        (window as any).AMAX = Amax({
            httpEndpoint: `${network.protocol}://${network.host}`,
            chainId: network.chainId,
        });
    }
    _getClient = (window as any).AMAX;
    return _getClient;
}

export function getScatter() {
    return (window as any).scatter;
}

export async function getContract(abi_name: string) {
    const client = await getClient();
    return await client.contract(abi_name);
}

// merchant=merchant1
// Private key: 5JZTokHvSvRXbskUqzhU5paafQbqUEj13qyQ9vDpW6NHK67gUbb
// Public key: AM5cizvBZobd1rNhw5N89wuqevzZbLxk2x6QZQVWvFdg3uTFpEJV

// merchant=merchantx
// Private key: 5JfxfDzeSTXiSbTzhxCo1eAmxbHd88THgS11ZZ6hLZqSPMCH636
// Public key: AM8HxjZAiqEY5mHCPXjCb7xEaU2tkmqdAek2TXX1CxTbnYMBqadX

// merchant=merchantx2
// Private key: 5Kk2pP4S1Q6MvMc6vit5vc8bSNmzyddRptcWCRngA2Mff4fynMP
// Public key: AM79jCfBdjEMP5fHmWMv4W1G6zMVGespL9nBvG4ETA5dJKQsRbgq

// conf=oxo.conf
// Private key: 5JGBUZNqqsJLNUANj2c7wyDZ7Ct84s92ojdRTcL8fu6uJxJcPrH
// Public key: AM6ttUE76EZw8E4MJVtHGaMfY8pcsSRf2EBqZhrHpyXZfhvho3ce

// book=oxo.cash
// Private key: 5J6MbPZ51wqu5jNJXb2Lv1X9mxVwn5UsaWEd4vs41kmcttVRHVG
// Public key: AM64xpLyp5CWPRs9YS1ce9rjBMXUS4azX5BtFh6wJAhvpZDT4Q2D
